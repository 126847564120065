import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{
            backgroundImage:
              "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="page-header-shape-1">
          <img src="assets/images/shapes/page-header-shape-1.png" alt="" />
        </div>
        <div className="container">
          <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li>
                <span>/</span>
              </li>
              <li>Glossary </li>
            </ul>
            <h2>Glossary </h2>
          </div>
        </div>
      </section>
      <section className="about-section">
        {/*===============spacing==============*/}
        <div className="pd_top_90" />
        {/*===============spacing==============*/}
        <div className="container mt-4 pt-4 mb-4">
        <h3>Glossary Of Common Terms In Shipping</h3>
        <article id="post-35531" className="container not-legacy post-35531 page type-page status-publish hentry">
          <br />
          <h2 className=" mt-medium mb-medium">A</h2>
          <p><dfn><strong>Accessorial Charge</strong>&nbsp;</dfn>A charge assessed in addition to ocean freight (charges), i.e., stuffing charges, loose cargo charge, terminal charges.</p>
          <p><dfn><strong>Ad Valorem</strong>&nbsp;</dfn>Latin for “According to the value”. When a shipper wishes to increase the liability of a carrier beyond the liability stated in the terms of the bill of lading, he places the actual value of the cargo in block 23 of the bill of lading. The carrier is then liable for the value of the cargo and charges a higher rate accordingly based on the value.</p>
          <p><strong><dfn>Agent&nbsp;</dfn></strong>One who negotiates contracts, or acts in the legal capacity of another party.</p>
          <p><dfn><strong>Alternate Port</strong>&nbsp;</dfn>Port offered by service where the vessel does not physically call. The cargo will be trucked or railed to or from the load or discharge port. The truck or rail portion is at the expense of carrier.</p>
          <p><dfn><strong>Arrival Notice</strong>&nbsp;</dfn>Port offered by service where the vessel does not physically call. The cargo will be trucked or railed to or from the load or discharge port. The truck or rail portion is at the expense of carrier.</p>
          <br />
          <h2 className=" mt-medium mb-medium">B</h2>
          <p><dfn><strong>Bill of Lading</strong>&nbsp;</dfn>A document signed by the agents or owners of a vessel, furnishing written evidence for the conveyance and delivery of merchandise sent by sea to a certain destination. It is both a receipt for merchandise and a contract to deliver it as freight.</p>
          <p><dfn><strong>Bonded Goods</strong>&nbsp;</dfn>Dutiable goods upon which excise duty has not been paid, i.e., goods in transit or warehoused pending use. The bond is the agreement entered into by the owner of the dutiable goods with Customs and the excise authority in which the owner promises to pay the duty when the goods are released for final distribution or use.</p>
          <p><strong><dfn>Booking&nbsp;</dfn></strong>Arrangements made by a shipper or forwarder with the carrier to reserve space on a vessel for the carriage of cargo.</p>
          <p><dfn><strong>Breakbulk Cargo</strong>&nbsp;</dfn>General cargo conventionally stevedored and stowed as opposed to bulk, unitized, or containerized cargo.</p>
          <p><strong><dfn>Broker&nbsp;</dfn></strong>One who arranges transportation and/or clearing of cargo without actually performing the transportation.</p>
          <br />
          <h2 className=" mt-medium mb-medium">C</h2>
          <p><strong><dfn>Carrier&nbsp;</dfn></strong>An individual, company, or corporation engaged in the transportation of goods.</p>
          <p><strong><dfn>Chassis&nbsp;</dfn></strong>Special trailer or undercarriage on which containers are moved over-the-road.</p>
          <p><strong><dfn>Clean Bill of Lading&nbsp;</dfn></strong>A bill of lading issued by the carrier on which no exceptions have been noted concerning the packaging or condition of the cargo in whole or in part.</p>
          <p><strong><dfn>Commodity&nbsp;</dfn></strong>Type of article shipped.</p>
          <p><strong><dfn>Conference&nbsp;</dfn></strong>An association of steamship carriers operating within the same specific trade route who have joined together to set rates and rules to be adhered to by all members.</p>
          <p><dfn><strong>Connecting Carrier</strong>&nbsp;</dfn>A carrier who has a direct connection with another carrier, under which freight is moved in joint-line service.</p>
          <p><strong><dfn>Consignee&nbsp;</dfn></strong>The individual or firm receiving shipped goods; often the owner of the cargo, when purchase is made FOB, country of export.</p>
          <p><strong><dfn>Consignor&nbsp;</dfn></strong>The individual or firm shipping the goods. More commonly referred to as the shipper.</p>
          <p><strong><dfn>Consolidated Cargo&nbsp;</dfn></strong>Cargo, consisting of shipments from two or more shippers, usually shipped by a firm called a consolidator. These shipments are made by the consolidator to take advantage of lower FCL rates; parts of these savings are usually passed on to the shipper.</p>
          <p><strong><dfn>Container&nbsp;</dfn></strong>A single, rigid, non-disposable dry cargo, ventilated, insulated, reefer, flat rack, vehicle rack, or open top container; with or without wheels or bogies attached; not less than 20 feet in length; having a closure or permanently-hinged door that allows ready access to the cargo. All types of containers will have construction and fittings, able to withstand, without permanent distortion, all the stresses that may be applied in normal use during continuous transportation. An ISO container is constructed to the specifications of the International Standards Organization.</p>
          <p><dfn><strong>Container Freight Station (CFS)</strong>&nbsp;</dfn>The physical facility where goods are received by carrier for loading into containers or unloading from containers and where carrier may assemble, hold, or store its containers or trailers.</p>
          <p><strong><dfn>Contraband&nbsp;</dfn></strong>Cargo which is prohibited by law.</p>
          <p><strong><dfn>Currency Adjustment Factor&nbsp;</dfn></strong>A percentage charge applied against the freight that adjusts the rate to account for a change in the valuation of the currency.</p>
          <p><dfn><strong>Customs</strong>&nbsp;</dfn>A Government authority designated to regulate the flow of goods to and from a country and to collect duties levied by a country on imports and or exports.</p>
          <p><strong><dfn>CY&nbsp;</dfn></strong>Container Yard.</p>
          <p><strong><dfn>CY/CY&nbsp;</dfn></strong>Full container load with cargo to be packed therein and unpacked there from at the shipper’s elected point or place and at shipper’s expense.</p>
          <br />
          <h2 className=" mt-medium mb-medium">D</h2>
          <p><strong><dfn>Demurrage&nbsp;</dfn></strong>Penalty charged shippers by receivers of freight usually at a stated sum per day for detention beyond the free time provided for loading or unloading.</p>
          <p><strong><dfn>Detention&nbsp;</dfn></strong>Compensation assessed against a shipper for the delayed return of carrier’s equipment beyond allowable free time. This is usually equipment picked up by the shipper at the load port for the purpose of stuffing the container for shipment and held at the shipper’s plant or warehouse for an excessive period of time. Detention charges may also be assessed against the consignee who fails to return stripped containers to the carriers within allowable free time.</p>
          <p><strong><dfn>Dock Receipt&nbsp;</dfn></strong>A receipt given for a shipment received or delivered at a pier or dock. When delivery of a foreign shipment is completed, the dock receipt is surrendered to the transportation line and a Bill of Lading is issued.</p>
          <p><dfn><strong>Documentation Charge</strong>&nbsp;</dfn>Fee charged shipper/consignee when the Bill of Lading is prepared by the carrier.</p>
          <p><dfn><strong>Door to Door</strong>&nbsp;</dfn>A container fully loaded by the shipper is delivered intact to the consignee.</p>
          <p><strong><dfn>Drayage&nbsp;</dfn></strong>Charge assessed for local hauling of cargo.</p>
          <br />
          <h2 className=" mt-medium mb-medium">E</h2>
          <p><strong><dfn>EDI&nbsp;</dfn></strong>Electronic Data Interchange.</p>
          <p><strong><dfn>EIR&nbsp;</dfn></strong>Equipment Interchange Report – A document executed by a truck carrier and a terminal transferring possession of a container or chassis from one to the other, and showing equipment condition at time of transfer.</p>
          <p><strong><dfn>Electronic Export Information (EEI)&nbsp;</dfn></strong>EEI is filed in the Automated Export System with Census. Regulations for filing the EEI can be found at http://www.census.gov/foreign-trade/www/</p>
          <br />
          <h2 className=" mt-medium mb-medium">F</h2>
          <p><strong><dfn>FAK&nbsp;</dfn></strong>Freight of all kinds. Usually refers to consolidated cargo.</p>
          <p><strong><dfn>FCL/FCL&nbsp;</dfn></strong>Same as CY/CY. Full container load with cargo to be packed therein and unpacked therefrom at the shipper’s elected point or place and at shipper’s expense.</p>
          <p><strong><dfn>FEU&nbsp;</dfn></strong>Forty-foot equivalent unit. A term used in indicating container vessel or terminal capacity. Two 20-foot containers equal one FEU.</p>
          <p><strong><dfn>Free on Board (F.O.B)&nbsp;</dfn></strong>The goods are placed on board a ship by the seller at a port of shipment named in the sales contract. The risk of loss or damage to the goods is transferred from the seller to the buyer when the goods pass the ship’s rail.</p>
          <br />
          <h2 className=" mt-medium mb-medium">H</h2>
          <p><dfn><strong>Heavy Lift Charge</strong>&nbsp;</dfn>A charge assessed in addition to ocean freight charges when cargo is too heavy to be handled by normal means, necessitating the use of special cranes.</p>
          <p><dfn><strong>House to Pier</strong>&nbsp;</dfn>A type of service where a container is stuffed by the shipper’s facility and stripped at the carrier’s facility.</p>
          <br />
          <h2 className=" mt-medium mb-medium">I</h2>
          <p><strong><dfn>Importer Security Filing (ISF)&nbsp;</dfn></strong>Commonly know as the “10+2” imitative, is a Customs and Border Protection (CBP) regulation that requires importers and vessel operating carriers to provide additional advance trade data to Customs and Border Protecting (CBP) pursuant to Section 203 of the SAFE Port Act of 2006 and section 343 (a) of the Trade Act of 2002, as amended by the Maritime Transportation Security Act of 2002, for non-bulk cargo shipments arriving into the United States by vessel.</p>
          <p><dfn><strong>In Bond</strong>&nbsp;</dfn>Term applied to the status of merchandise admitted provisionally to a country without payment of duties either for storage in a bonded warehouse or for transshipment to another point where duties will eventually be imposed and paid.</p>
          <p><dfn><strong>Intermodal</strong>&nbsp;</dfn>Used to denote ability of containers to change mode of transport from rail to truck to ship, in any order.</p>
          <p><dfn><strong>Internal Transaction Number (ITN)</strong>&nbsp;</dfn>Confirmation number received from Automated Export System (AES) when Electronic Export Information (EEI) has been completed and accepted by Census.</p>
          <p><dfn><strong>International Standards Organization (ISO)</strong>&nbsp;</dfn>Worldwide organization formed to promote development of standards to facilitate the international carriage and exchange of goods and service and to develop mutual cooperation in the spheres of intellectual, scientific, technological, and economic activities.</p>
          <p><dfn><strong>Invoice</strong>&nbsp;</dfn>A commercial document in a form consistent with the trade identifying both buyer and seller, reflecting the price actually paid or payable, the terms of sale, the currency used for payment, the articles sold, and other specific information required for duty assessment purposes.</p>
          <br />
          <h2 className=" mt-medium mb-medium">K</h2>
          <p><strong><dfn>Kilogram&nbsp;</dfn></strong>2.2046 pounds – commonly referred to as kilo (KG).</p>
          <br />
          <h2 className=" mt-medium mb-medium">L</h2>
          <p><dfn><strong>LCL/FCL</strong>&nbsp;</dfn>Same as CFS/CY. Full container loads stuffed and stripped at the carrier’s expense.</p>
          <p><strong><dfn>Letter of Credit&nbsp;</dfn></strong>A letter of agreement issued by a bank stating that a purchaser has established a line of credit in the seller’s favor and confirming that payment will be made upon presentation of certain documents, provided that all documents are in agreement with the provisions of the letter of credit.</p>
          <p><dfn><strong>Lift On/Lift Off (LOLO)</strong>&nbsp;</dfn>Cargo loaded or unloaded by either ship or shore cranes.</p>
          <p><strong><dfn>Logistics&nbsp;</dfn></strong>The management of both inbound and outbound materials, parts, supplies, and finished goods. Includes such activities as; production scheduling, forecasting, customer service, order entry, inventory control, and product allocation among customers.</p>
          <br />
          <h2 className=" mt-medium mb-medium">M</h2>
          <p><dfn><strong>Marks &amp; Numbers</strong>&nbsp;</dfn>Marks and/or numbers placed on cargo to identify the shipper, forwarder, and destination; marks to identify the cargo during sorting.</p>
          <br />
          <h2 className=" mt-medium mb-medium">N</h2>
          <p><strong><dfn>NIT&nbsp;</dfn></strong>Not in trailer.</p>
          <p><dfn><strong>NOS (Not Otherwise Specified)</strong>&nbsp;</dfn>A term used to indicate a lack of a specific named commodity in a tariff or in the regulations governing the carriage of hazardous cargo.</p>
          <p><dfn><strong>NVOCC (Non-vessel Operating Common Carrier)</strong>&nbsp;</dfn>A carrier defined by maritime law, offering an international cargo transport service through the use of underlying carriers and under one’s own rate structure in accordance with tariffs.</p>
          <br />
          <h2 className=" mt-medium mb-medium">P</h2>
          <p><strong><dfn>Packing List&nbsp;</dfn></strong>Usually issued in conjunction with a suppliers invoice detailing the types of merchandise ,size and quantity per shipping unit.</p>
          <p><strong><dfn>Paid By Agent&nbsp;</dfn></strong>A charge on the bill of lading that a carrier collects for a freight forwarder and, hence, reimburses him.</p>
          <p><dfn><strong>Phytosanitary Inspection Certificate</strong>&nbsp;</dfn>A certificate issued by the US Department of Agriculture to satisfy import regulations of foreign countries indicating that a U.S. shipment has been inspected and is free from harmful pests and plant diseases.</p>
          <p><strong><dfn>Pier&nbsp;</dfn></strong>The location in a seaport at which cargo arrives or departs. A dock for loading or unloading ships or vessels. A type of wharf running at an angle with the shoreline of a body of water.</p>
          <p><dfn><strong>Pier to House</strong>&nbsp;</dfn>A container loaded at the carrier’s facility and delivered fully intact to the consignee.</p>
          <p><dfn><strong>Pier to Pier</strong>&nbsp;</dfn>A container that is stuffed at the carrier’s facility and stripped at the destination by the carrier’s facility.</p>
          <br />
          <h2 className=" mt-medium mb-medium">R</h2>
          <p><dfn><strong>Ramp</strong>&nbsp;</dfn>Railroad term used to describe an intermodal terminal.</p>
          <p><strong><dfn>Roll-on/Roll-off (Ro/Ro)&nbsp;</dfn></strong>Direct drive-on and drive-off of highway trailers, railcars, and other wheeled cargo or vehicles, from and to specially adapted ships.</p>
          <br />
          <h2 className=" mt-medium mb-medium">S</h2>
          <p><dfn><strong>Schedule B</strong>&nbsp;</dfn>Refers to schedule B statistical classification of domestic and foreign commodities exported from the United States.</p>
          <p><strong><dfn>Seal&nbsp;</dfn></strong>An individually numbered metal, plastic, or wire strip used to seal the doors of a container for security or Customs purposes.</p>
          <p><dfn><strong>Shipper’s Export Declaration (SED)</strong>&nbsp;</dfn>Electronic Export Information (EEI) filed in the Automated Export System (AES) replaces the Shippers Export Declaration (SED).</p>
          <p><dfn><strong>Shipper’s Load and Count (SLAC)</strong>&nbsp;</dfn>Standard bill of lading and manifest clause used when containerized cargo is loaded and sealed by the shipper, and the piece count in the container is not checked or otherwise verified by the carrier.</p>
          <p><dfn><strong>Short Shipped</strong>&nbsp;</dfn>A shipment which was originally scheduled for a particular vessel/voyage however, did not make the vessel.</p>
          <p><strong><dfn>Shutout&nbsp;</dfn></strong>Cargo shortshipped by the carrier due to lack of space on the vessel, usually due to overbooking.</p>
          <p><dfn><strong>Split Shipment</strong>&nbsp;</dfn>Multiple container load shipment booked for one vessel but split and moved on one or more vessels.</p>
          <p><strong><dfn>Stevedore&nbsp;</dfn></strong>Individual or firm employing longshoremen for the purpose of loading and unloading a vessel. individual, or firm employing longshoremen for the purpose of loading and unloading a vessel.</p>
          <p><strong><dfn>Stowage Plan&nbsp;</dfn></strong>Diagrammatic sketch of vessel showing location of cargo as stowed in the vessel’s hold(s).</p>
          <p><strong><dfn>Straight Bill of Lading&nbsp;</dfn></strong>A non-negotiable transportation receipt that directs the carrier to deliver the shipment to any authorized person at the destination point. Such bills of lading must bear the clause ‘straight bill of lading- not negotiable’.</p>
          <p><strong><dfn>Stripping&nbsp;</dfn></strong>A term often used to denote the process of removing cargo from a container.</p>
          <p><strong><dfn>Stuffing&nbsp;</dfn></strong>A term often used to denote the process of loading cargo into a container.</p>
          <br />
          <h2 className=" mt-medium mb-medium">T</h2>
          <p><strong><dfn>Tare Weight&nbsp;</dfn></strong>The weight of an empty container.</p>
          <p><strong><dfn>Tariff&nbsp;</dfn></strong>A list of rates, additional charges, regulations, and requirements of a carrier, port, or conference. Ocean tariffs are regulated by the Federal Maritime Commission. Inland tariffs, as well as ocean tariffs to / from Puerto Rico / U.S. Virgin Islands are regulated by the Surface Transportation Board.</p>
          <p><strong><dfn>Terminal&nbsp;</dfn></strong>1. A berthside area where cargo is loaded to and discharged from vessels. 2. A depot – usually inland – where containers are brought for stripping/storage.</p>
          <p><strong><dfn>TEU&nbsp;</dfn></strong>Twenty-foot equivalent unit. The common unit used in indicating the capacity of a container vessel or terminal. A 40-foot container is equal to two TEU’s.</p>
          <p><dfn><strong>Transit Time</strong>&nbsp;</dfn>A time period for cargo to move between two points (i.e., from shipper to consignee)</p>
          <br />
          <h2 className=" mt-medium mb-medium">W</h2>
          <p><strong><dfn>Wharfage&nbsp;</dfn></strong>A charge assessed by a pier or dock owner for handling incoming or outgoing cargo.</p>
        </article>
        </div>
        {/*===============spacing==============*/}
        <div className="pd_bottom_70" />
        {/*===============spacing==============*/}
      </section>

      <Footer />
    </>
  );
}
