import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{ backgroundImage: "url(assets/images/banner.jpg)" }}
        ></div>
        <div className="container">
          <div className="page-header__inner">
            <h2>Cargo Insurance</h2>
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>

              <li>
                <span>/</span>
              </li>
              <li>Cargo Insurance</li>
            </ul>
          </div>
        </div>
      </section>
      {/*Page Header End*/}
      {/*Services Details Start*/}
      <div className="services-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-5">
              <div className="services-details__sidebar">
                <div className="services-details__services-list">
                  <ul className="services-details__services list-unstyled">
                    <li className="">
                      <Link to="/Air">Air Freight</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Ocean">Ocean Freight</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Inland">Inland Transport</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Third">Third Party Logistics (3PL)</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Warehousing">Warehousing & Distribution</Link>
                    </li>
                    <li className="active ">
                      <Link to="/Cargo">Cargo Insurance</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Project">Project Management</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Total">Total Supply Chain Solution</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7">
              <div className="services-details__right">
                <div className="services-details__img">
                  <img src="assets/images/Cargo Insurance.jpg" alt="" />
                </div>
                <h3 className="services-details__title-1">Cargo Insurance</h3>
                <p className="services-details__text-1">
                  <p>
                    When it comes to transit, it's imperative to note that
                    various stakeholders in the logistics chain, including
                    freight forwarders, shipping lines, airlines, trucking
                    companies, and railroads, typically incorporate limited
                    liability clauses within their terms and conditions. In
                    light of this, we strongly advise all our esteemed customers
                    to consider safeguarding their cargo's value against
                    potential loss or damage throughout its journey. This can be
                    achieved through cargo insurance, serving as a protective
                    layer that extends from door to door.
                  </p>

                  <p>
                    At {companyname}, while we undertake every
                    measure to ensure safe passage, the unpredictable nature of
                    transportation necessitates proactive protection. Our cargo
                    insurance is strategically designed to complement your
                    existing policies, offering a safety net for claims or
                    sporadic requirements without affecting your annual rates.
                    Opting for our Cargo Insurance empowers you to mitigate
                    financial risks stemming from physical loss or damage to
                    your goods.
                  </p>

                  <p>
                    With a profound understanding of the vulnerabilities
                    inherent in transit, we handle each cargo with a blend of
                    personalized and professional attention. Collaborating with
                    our international insurance partners, we extend
                    cost-effective insurance coverage to encompass a wide array
                    of cargo types.
                  </p>

                  <h3>Key Benefits of Our Cargo Insurance:</h3>

                  <ul>
                    <li>
                      All-Risk Coverage, including Strike and War Risk (ICC A
                      Terms)
                    </li>
                    <li>
                      Comprehensive Compensation, including Invoice Value,
                      Freight, and up to 10% Profit
                    </li>
                    <li>
                      Cargo Insurance Tailored to Your Specific Requirements
                    </li>
                  </ul>

                  <p>
                    Our cargo insurance offerings provide flexibility, allowing
                    you to opt for either per-shipment coverage or an annual
                    policy. It's essential to bear in mind that cargo insurance
                    doesn't typically cover indirect damages like delays. In
                    scenarios where urgency is paramount, you can explore
                    elevated service tiers that enhance transit time
                    reliability.
                  </p>

                  <p>
                    At {companyname}, we not only ensure your
                    cargo's physical journey but also offer the means to fortify
                    your financial security, underscoring our commitment to a
                    comprehensive and safeguarded shipping experience.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
