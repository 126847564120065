import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="page_header_default style_one ">
        <div className="parallax_cover">
          <div className="simpleParallax">
            <img
              src="assets/images/page-header-default.jpg"
              alt="bg_image"
              className="cover-parallax"
            />
          </div>
        </div>
        <div className="page_header_content">
          <div className="auto-container">
            <div className="row">
              <div className="col-md-12" style={{ marginTop: "200px" }}>
                <div className="banner_title_inner">
                  <div className="title_page">Multimodal freight</div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="breadcrumbs creote">
                  <ul className="breadcrumb m-auto">
                    <li>
                      <Link to="/Home">Home</Link>
                    </li>
                    <li className="active">Multimodal freight</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="content" className="site-content">
        <div className="auto-container">
          <div className="row default_row">
            <div
              id="primary"
              className="content-area service col-lg-8 col-md-12 col-sm-12 col-xs-12"
            >
              <main id="main" className="site-main" role="main">
                {/*===============spacing==============*/}
                <div className="pd_top_85" />
                {/*===============spacing==============*/}
                <article className="clearfix service type-service status-publish has-post-thumbnail hentry">
                  <div className="title_all_box style_one dark_color">
                    <div className="title_sections left">
                      <div className="before_title">Services</div>
                      <div className="title">Multimodal freight</div>

                      <p>
                        Multi-modal freight represents a strategic integration
                        of sea and air freight, combining these modes of
                        transportation to precisely cater to our customers'
                        unique needs. This approach involves skillfully blending
                        various transportation modes, including rail, road, sea,
                        and air, to craft a comprehensive logistics solution.
                        What sets multi-modal transport apart is the involvement
                        of a single operator who orchestrates all aspects of the
                        journey.
                      </p>
                    </div>
                  </div>
                  <div className="row no-space">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-5 mb-lg-5 mb-xl-0 ps-0 ps-lg-0 pe-0 pe-lg-0 pe-xl-3">
                      {/*===============spacing==============*/}
                      <div className="pd_bottom_25" />
                      {/*===============spacing==============*/}

                      <div className="simple_image_boxes">
                        <img
                          src="assets/images/Multimodal freight.jpg"
                          className="object-fit-cover-center height_570px"
                          alt="image"
                        />
                      </div>
                      <div className="description_box">
                        <p>
                          Defined with precision within the United Nations
                          Convention on International Carriage of Goods,
                          multi-modal transport carries its own distinct legal
                          concept. This is critical as it establishes an
                          operator-specific liability framework that differs
                          from those applicable to individual transportation
                          modes.
                        </p>
                        <p>
                          The prominent benefits of multi-modal transport
                          encompass:
                        </p>
                        <p>
                          <strong>1. Minimized Transshipment Time:</strong>{" "}
                          Through leveraging its network, the multi-modal
                          transport operator adeptly optimizes the time spent
                          during freight transits. This streamlines
                          transshipment, resulting in a swift and efficient
                          process, particularly at the administrative level.
                        </p>
                        <p>
                          <strong>2. Expedited Routes:</strong> One of the key
                          strengths of multi-modal transport is its freedom from
                          geographical constraints. This logistics solution can
                          seamlessly exploit a diverse range of transportation
                          options, including ships, planes, trains, and trucks,
                          thereby eliminating geographical limitations and
                          enabling faster delivery.
                        </p>
                        <p>
                          <strong>3. Streamlined Administrative Burden:</strong>{" "}
                          A single operator takes charge of the entire transport
                          journey, providing an end-to-end solution. This
                          alleviates the complexities associated with managing
                          administrative tasks at various stages, contributing
                          to a more seamless process.
                        </p>
                        <p>
                          <strong>4. Cost-Efficiency:</strong> Multi-modal
                          freight optimizes costs by selecting the most
                          competitive routes available. With our extensive
                          network and industry experience, we are able to offer
                          highly competitive logistics solutions that drive cost
                          savings.
                        </p>
                        <p>
                          Embracing multi-modal transport means embracing a
                          comprehensive, versatile, and efficient approach to
                          logistics. By harnessing the strengths of both sea and
                          air freight, we craft tailored solutions that optimize
                          time, streamline processes, and deliver substantial
                          value to our customers' supply chain operations.
                        </p>
                      </div>
                    </div>
                  </div>
                </article>
                {/*===============spacing==============*/}
                <div className="pd_bottom_65" />
                {/*===============spacing==============*/}
              </main>
            </div>
            <aside
              id="secondary"
              className="widget-area all_side_bar col-lg-4 col-md-12 col-sm-12"
            >
              <div className="service_siderbar side_bar">
                {/*===============spacing==============*/}
                <div className="pd_top_85" />
                {/*===============spacing==============*/}
                <div className="widgets_grid_box">
                  <div className="widget creote_widget_service_list">
                    <h4 className="widget-title">Our Services</h4>
                    <ul className="service_list_box">
                      <li>
                        <Link to="/Sea">Sea freight</Link>
                      </li>
                      <li>
                        <Link to="/Air">Air freight</Link>
                      </li>
                      <li>
                        <Link to="/Rail">Rail freight</Link>
                      </li>
                      <li>
                        <Link to="/Road">Road freight</Link>
                      </li>
                      <li>
                        <Link to="/Multimodal">Multimodal freight</Link>
                      </li>
                      <li>
                        <Link to="/Door">Door to Door</Link>
                      </li>
                      <li>
                        <Link to="/Express">Express Shipping</Link>
                      </li>
                      <li>
                        <Link to="/Customs">Customs Clearance</Link>
                      </li>
                      <li>
                        <Link to="/Cross">Cross Dock Logistics</Link>
                      </li>
                      <li>
                        <Link to="/Global">Global 3PL Logistics</Link>
                      </li>
                      <li>
                        <Link to="/Project">Project logistics</Link>
                      </li>
                      <li>
                        <Link to="/Contract">Contract logistics</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
