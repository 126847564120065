import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{ backgroundImage: "url(assets/images/banner.jpg)" }}
        ></div>
        <div className="container">
          <div className="page-header__inner">
            <h2>Industrial</h2>
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>

              <li>
                <span>/</span>
              </li>
              <li>Industrial</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="blog-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7">
              <div className="blog-details__left">
                <div className="blog-details__img">
                  <img
                    src="assets/images/Industrial.jpg"
                    style={{ height: "400px" }}
                    alt=""
                  />
                </div>
                <div className="blog-details__content">
                  <h2 className="text-center  p-2">Industrial Industry</h2>
                  <p className="blog-details__text-1">
                    <p>
                      Meeting your objectives requires not just competence, but
                      a mastery of technical skills, an impeccable strategic
                      approach, and an unwavering commitment to precision.
                      Success in your endeavors hinges on meticulous execution
                      and vigilant monitoring.
                    </p>

                    <p>
                      In response to the escalating demands of the global
                      market, air freight has risen to prominence as an
                      indispensable facet of effective supply chain management.
                    </p>

                    <p>
                      Beyond just integrated freight and warehousing services,
                      Hong Kong is dedicated to offering its clientele an
                      all-encompassing, one-stop-shop end-to-end solution. This
                      commitment revolves around affordability, exceptional
                      quality, personalized customization, and adaptable
                      flexibility. The primary aim is to foster seamless
                      information flow and amplify the efficiency of logistics.
                      This holistic solution doesn't solely yield tangible,
                      quantitative benefits for the bottom line; it also
                      delivers intangible advantages by enhancing customer
                      satisfaction through swifter response times and reduced
                      supply chain cycle durations.
                    </p>

                    <p>Our Comprehensive Offerings Include:</p>

                    <ul>
                      <li>Logistics Diagnosis</li>
                      <li>Process Mapping and Re-engineering</li>
                      <li>
                        Tailor-Made Integrated Logistics Solutions by Local and
                        Group Experts, Encompassing Contingency Planning
                      </li>
                      <li>
                        Centralized Coordination, Collaborating with
                        Sub-contractors Embedded within the Solution Framework
                      </li>
                      <li>Consultancy</li>
                    </ul>

                    <p>
                      At its core, this approach is not just about offering
                      services – it's about architecting solutions that
                      holistically address the intricate landscape of modern
                      logistics. By leveraging local and group expertise, we are
                      equipped to guide your operations through strategic
                      navigation, ultimately optimizing your logistics journey.
                    </p>

                    <p>
                      A pioneering force in the logistics realm, offering an
                      all-encompassing range of sustainable and multimodal
                      transportation solutions meticulously tailored to combat
                      the intricacies of your supply chain challenges.
                    </p>

                    <p>
                      Our dedication extends to optimizing your supply flows,
                      distribution networks, and after-sales services. With an
                      adept understanding of the diverse landscapes your
                      projects navigate, we expertly mold our solutions to fit
                      each unique endeavor—no matter how intricate or daunting.
                      The hallmark of our approach lies in customizing solutions
                      that resonate with your specific requirements.
                    </p>

                    <p>
                      Leveraging an intimate familiarity with your industry and
                      this finely-tailored approach, we orchestrate your
                      logistics in a manner that maximizes efficiency and
                      economy. At {companyname}, we understand that
                      excellence isn't a one-size-fits-all concept; it's about
                      crafting strategies that seamlessly align with your
                      vision, ensuring not just logistics, but a trajectory
                      toward success.
                    </p>
                  </p>
                  <div className="blog-details__bottom"></div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="sidebar">
                <div className="sidebar__single sidebar__category">
                  <h3 className="sidebar__title">Industry Sectors</h3>
                  <ul className="sidebar__category-list list-unstyled">
                    <li>
                      <Link to="/Automotive">Automotive</Link>
                    </li>
                    <li>
                      <Link to="/Electronics">Electronics</Link>
                    </li>
                    <li>
                      <Link to="/Retail">Retail</Link>
                    </li>
                    <li>
                      <Link to="/High">High Tech</Link>
                    </li>
                    <li>
                      <Link to="/Industrial">Industrial</Link>
                    </li>
                    <li>
                      <Link to="/Apparel">Apparel</Link>
                    </li>
                    <li>
                      <Link to="/Healthcare">Healthcare</Link>
                    </li>
                    <li>
                      <Link to="/Food">Food & Beverages</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
