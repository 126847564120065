import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="page_header_default style_one ">
        <div className="parallax_cover">
          <div className="simpleParallax">
            <img
              src="assets/images/page-header-default.jpg"
              alt="bg_image"
              className="cover-parallax"
            />
          </div>
        </div>
        <div className="page_header_content">
          <div className="auto-container">
            <div className="row">
              <div className="col-md-12" style={{ marginTop: "200px" }}>
                <div className="banner_title_inner">
                  <div className="title_page">Wood</div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="breadcrumbs creote">
                  <ul className="breadcrumb m-auto">
                    <li>
                      <Link to="/Home">Home</Link>
                    </li>
                    <li className="active">Wood</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="about-section">
        {/*===============spacing==============*/}
        <div className="pd_top_90" />
        {/*===============spacing==============*/}
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-12 ">
              <div className="about_content position-relative z_99">
                <div className="title_all_box style_one text-left  dark_color">
                  <div className="title_sections">
                    <div className="before_title">Industries</div>
                    <h2>Wood</h2>
                  </div>
                </div>
                
             
                {/*===============spacing==============*/}
                <div className="description_box">
                <p>From the heart of forestry operations directly to the sawmill, or seamlessly from the timber yard to the subsequent stages of processing within the pulp and paper industry.</p>

<p>Our dedicated team of experts, well-versed in the intricacies of the timber industry, takes the reins in conceptualizing and orchestrating ingenious and comprehensive solutions for international material shipments. This holistic approach encompasses all aspects of the journey, ensuring a seamless and efficient process from start to finish.</p>

<p>With us, you gain the advantage of accessing a unified resource that skillfully navigates the complexities of the timber industry's supply chain. This commitment to providing integrated solutions from a singular source is a testament to our dedication to simplifying logistics, optimizing processes, and enhancing the efficiency of material transport, ultimately contributing to the sustained success of the timber sector.</p>
                </div>
                {/*===============spacing==============*/}
                
              
              </div>
            </div>
            <div className="col-xl-6 col-lg-12">
              <div className="image_boxes style_two">
                <img src="assets/images/shape-1.png" className="background_image" alt="image" />
                <div className="image ">
                  <img src="assets/images/wood industry.jpg" className="img-fluid" alt="image" />
                </div>
               
                <div className="authour_quotes">
                  <i className="icon-quote" />
                  <h6>Wood</h6>
                  <p>Industries</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*===============spacing==============*/}
        <div className="pd_bottom_70" />
        {/*===============spacing==============*/}
      </section>

      <Footer />
    </>
  );
}
