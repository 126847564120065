import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{
            backgroundImage:
              "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="page-header-shape-1">
          <img src="assets/images/shapes/page-header-shape-1.png" alt="" />
        </div>
        <div className="container">
          <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li>
                <span>/</span>
              </li>
              <li>Import Export Consolidation</li>
            </ul>
            <h2>Import Export Consolidation</h2>
          </div>
        </div>
      </section>
      <section className="about-section">
        {/*===============spacing==============*/}
        <div className="pd_top_90" />
        {/*===============spacing==============*/}
        <div className="container">
        <div className="container py-4">
        <div className="text-center wthree-title pb-sm-5 pb-3">
          <h3 className="w3l-sub">Import Export Consolidation</h3>
        </div>
        <div className="row">
         
          <div className="col-lg-12">
            <h3 />
            <p>We co-ordinate your logistics related to imports &amp; exports from country of origin to destination with
              a high degree of diligence and efficiency.</p>
            <p>Any persons who import or export any goods (other than exempted articles) are required to lodge
              accurate and complete import/export declarations to Hong Kong Customs within 14 days after the
              import/export of goods.</p>
            <p>We have designed programs which extends to you an immediate command over your integrated flow of
              material. Documents and information thereby giving you the needed flexibility to react to changing
              market conditions.</p>
          </div>
        </div>
        <br />
        <br />
        <div className="container">
          <h3>Customs taxes and tariffs</h3>
          <p>With its Free-trade port status, Hong Kong doesn’t claim customs duties for general goods that are
            entering its territory. Only a few types of goods are submitted to taxes:</p>
          <ul>
            <li><i className="fa fa-check" />&nbsp;&nbsp;For tobacco: The tax is calculated based on the total
              volume</li>
            <li><i className="fa fa-check" />&nbsp;&nbsp;Alcoholic beverage which contains more than 30% of
              alcohol: the tax is equivalent to 100% of the CIF value.</li>
            <li><i className="fa fa-check" />&nbsp;&nbsp;Wine: Since 2009, the tax is equivalent to 60% of the
              CIF value.</li>
            <li><i className="fa fa-check" />&nbsp;&nbsp;Beer: 30% of the CIF value.</li>
            <li><i className="fa fa-check" />&nbsp;&nbsp;Oil products: to calculate the tax, you must consider
              its financial value, but also its volume.</li>
            <li><i className="fa fa-check" />&nbsp;&nbsp;New vehicles: 35 to 100% of the total value.</li>
          </ul>
          <br />
          <br />
          <h3>Import licenses are necessary to import this following products:</h3>
          <br />
          <ul>
            <li><i className="fa fa-check" />&nbsp;&nbsp;Chemical products: as determined in the Control Laws of
              Chemical Products. The licenses must be claimed at the “ Environmental Protection Department” For
              further information, visit the official page: Chemical Products HK</li>
            <br />
            <li><i className="fa fa-check" />&nbsp;&nbsp;Pharmaceutical products: licenses must be claimed at
              the “Drug Registration and Import/Export Control Division of the Department of Health”</li>
            <br />
            <li><i className="fa fa-check" />&nbsp;&nbsp;Living animals: If it’s for trade purposes, it will be
              very difficult for you to import living animals to Hong Kong. You must ask a license to the
              “Agriculture, Fisheries and Conservation Department”.</li>
            <br />
            <li><i className="fa fa-check" />&nbsp;&nbsp;Vegetables: principles remain the same, you need a
              license delivered by the Vegetable and Pesticides Division from the “Agriculture, Fisheries and
              Conservation Department”.</li>
            <br />
            <li><i className="fa fa-check" />&nbsp;&nbsp;Textiles: Import licenses are not necessary since 2014.
              Further information on the “ Trade and Industry Department “ website.</li>
            <br />
            <li><i className="fa fa-check" />&nbsp;&nbsp;Food (rice, frozen meat and fish) : The meat products,
              fresh or frozen requires an Import License delivered by the “ the Food and Environmental Hygiene
              Department “. Sanitary certificate from the country of origin must be delivered with the import
              licenses.</li>
            <br />
            <li><i className="fa fa-check" />&nbsp;&nbsp;Licenses for importing vehicles, alcoholic beverages,
              tobacco and oil products are claimed at the “Customs and Excise Department </li>
          </ul>
          <br />
          <br />
          <h3>Export goods from Hong Kong</h3>
          <p style={{fontSize: '17px'}}>More than 75% of the Hong Kongese exportations are sent to other Asian markets.
          </p>
          <br />
          <br />
          <h3> Non-tariff barriers</h3>
          <br />
          <p>Even if the reassignment of Hong Kong to China happened more than 20 years ago, the Hong Kong Special
            Administrative Region of the People’s Republic of China (HKSAR) kept its customs regulation. In fact,
            Hong Kong still has its Free-trade port status, and remain a separated custom territory from China. It
            allows Hong Kong to maintain its function in the international organism, and its numerous international
            commercial agreements.</p>
          <br />
          <br />
          <h3>Several documents are required to ease the custom clearance:</h3>
          <br />
          <ul>
            <li><i className="fa fa-check" />&nbsp;&nbsp;A manifesto</li>
            <li><i className="fa fa-check" />&nbsp;&nbsp;An import/export license or a withdrawal permit, if
              asked.</li>
            <li><i className="fa fa-check" />&nbsp;&nbsp;A photocopy of the detention notice (where appropriate)
            </li>
            <li><i className="fa fa-check" />&nbsp;&nbsp;Few documents like the Bill of Lading, the Air freight
              letter, the pro forma invoice and the packing list.</li>
          </ul>
          <br />
          <br />
          <h3>The port effectiveness</h3>
          <p style={{fontSize: '17px'}}>Hong Kong port is a true model of competitivity. It’s one of the most important
            and efficient port worldwide. He possesses the largest container capacity in the world.</p>
          <p>Its strategical position in deep waters, ease the vessel transit and explains the intense maritime
            activity of the region. Nowadays, there’s only a few industries in Hong Kong and most of its activity
            depends on the tertiary sector. The port has a key role for Hong Kong, because 35% of the containers
            routed to the port comes from Continental China.</p>
          <p>Nowadays, approximately 7000 containers are passing through the port everyday. It only takes less than 10
            hours to unload and to load a vessel. The biggest vessels have a 10,000-container capacity. There are
            approximately a thousand of barges which are transporting containers from the ships that stay off, to
            the port.</p>
          <p>The maritime transport sector is vital to support Hong Kong’s status of the seventh largest global trade
            place. In 2016, Hong Kong treated 257 million of metric tons of maritime freight. About 65% of the goods
            transported by Ocean freight were handled by deep water vessels. Amongst them, 93,4 millions of metric
            tons (57%) were transhipments goods. Continental China was both the principal source and destination of
            the transhipment goods of Hong Kong.</p>
        </div>
      </div>
        </div>
        {/*===============spacing==============*/}
        <div className="pd_bottom_70" />
        {/*===============spacing==============*/}
      </section>

      <Footer />
    </>
  );
}
