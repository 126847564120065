import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{ backgroundImage: "url(assets/images/banner.jpg)" }}
        ></div>
        <div className="container">
          <div className="page-header__inner">
            <h2>Retail</h2>
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>

              <li>
                <span>/</span>
              </li>
              <li>Retail</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="blog-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7">
              <div className="blog-details__left">
                <div className="blog-details__img">
                  <img
                    src="assets/images/Retail.jpg"
                    style={{ height: "400px" }}
                    alt=""
                  />
                </div>
                <div className="blog-details__content">
                  <h2 className="text-center  p-2">Retail Industry</h2>
                  <p className="blog-details__text-1">
                    <p>
                      In the realm of fashion and lifestyle logistics, {companyname} emerges as the beacon of versatility,
                      offering an expansive array of options. Our commitment is
                      clear: to provide comprehensive supply chain management
                      and logistics solutions that cater to established
                      enterprises and emerging ventures alike. For well-known
                      brands and those on the rise, our promise remains
                      unwavering – efficient, technologically-driven solutions
                      that translate into enhanced profitability.
                    </p>

                    <p>
                      Retail firms, the beating heart of the fashion industry,
                      find in {companyname} a steadfast partner. Our
                      arsenal includes specialized, cutting-edge, and
                      tech-centric solutions that resonate with the dynamic
                      needs of the sector. As we navigate the landscape of
                      apparel shipping, we tailor our services to each
                      assignment, ensuring that the best delivery options align
                      with the unique requirements of our business partners.
                    </p>

                    <p>
                      Furthermore, we extend our partnership to assist clients
                      in streamlining costs. By optimizing garment
                      transportation expenses and meticulously selecting
                      shipping strategies, lines, and modes, we contribute to
                      the refinement of cost-cutting strategies. At {companyname}, we're not just moving cargo; we're
                      enhancing the fashion and lifestyle industry by providing
                      solutions that bolster growth, efficiency, and strategic
                      advancement.
                    </p>
                  </p>
                  <div className="blog-details__bottom"></div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="sidebar">
                <div className="sidebar__single sidebar__category">
                  <h3 className="sidebar__title">Industry Sectors</h3>
                  <ul className="sidebar__category-list list-unstyled">
                    <li>
                      <Link to="/Automotive">Automotive</Link>
                    </li>
                    <li>
                      <Link to="/Electronics">Electronics</Link>
                    </li>
                    <li>
                      <Link to="/Retail">Retail</Link>
                    </li>
                    <li>
                      <Link to="/High">High Tech</Link>
                    </li>
                    <li>
                      <Link to="/Industrial">Industrial</Link>
                    </li>
                    <li>
                      <Link to="/Apparel">Apparel</Link>
                    </li>
                    <li>
                      <Link to="/Healthcare">Healthcare</Link>
                    </li>
                    <li>
                      <Link to="/Food">Food & Beverages</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
