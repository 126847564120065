import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{ backgroundImage: "url(assets/images/banner.jpg)" }}
        ></div>
        <div className="container">
          <div className="page-header__inner">
            <h2>Total Supply Chain Solution</h2>
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>

              <li>
                <span>/</span>
              </li>
              <li>Total Supply Chain Solution</li>
            </ul>
          </div>
        </div>
      </section>
      {/*Page Header End*/}
      {/*Services Details Start*/}
      <div className="services-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-5">
              <div className="services-details__sidebar">
                <div className="services-details__services-list">
                  <ul className="services-details__services list-unstyled">
                    <li className="">
                      <Link to="/Air">Air Freight</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Ocean">Ocean Freight</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Inland">Inland Transport</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Third">Third Party Logistics (3PL)</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Warehousing">Warehousing & Distribution</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Cargo">Cargo Insurance</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Project">Project Management</Link>
                    </li>
                    <li className=" active">
                      <Link to="/Total">Total Supply Chain Solution</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7">
              <div className="services-details__right">
                <div className="services-details__img">
                  <img
                    src="assets/images/Total Supply Chain Solution.jpg"
                    alt=""
                  />
                </div>
                <h3 className="services-details__title-1">
                  Total Supply Chain Solution
                </h3>
                <p className="services-details__text-1">
                  <p>
                    In response to the escalating demands of the global market,
                    air freight has risen to prominence as an indispensable
                    facet of effective supply chain management.
                  </p>

                  <p>
                    Beyond just integrated freight and warehousing services,
                    Hong Kong is dedicated to offering its clientele an
                    all-encompassing, one-stop-shop end-to-end solution. This
                    commitment revolves around affordability, exceptional
                    quality, personalized customization, and adaptable
                    flexibility. The primary aim is to foster seamless
                    information flow and amplify the efficiency of logistics.
                    This holistic solution doesn't solely yield tangible,
                    quantitative benefits for the bottom line; it also delivers
                    intangible advantages by enhancing customer satisfaction
                    through swifter response times and reduced supply chain
                    cycle durations.
                  </p>

                  <h3>Our Comprehensive Offerings Include:</h3>

                  <ul>
                    <li>Logistics Diagnosis</li>
                    <li>Process Mapping and Re-engineering</li>
                    <li>
                      Tailor-Made Integrated Logistics Solutions by Local and
                      Group Experts, Encompassing Contingency Planning
                    </li>
                    <li>
                      Centralized Coordination, Collaborating with
                      Sub-contractors Embedded within the Solution Framework
                    </li>
                    <li>Consultancy</li>
                  </ul>

                  <p>
                    At its core, this approach is not just about offering
                    services – it's about architecting solutions that
                    holistically address the intricate landscape of modern
                    logistics. By leveraging local and group expertise, we are
                    equipped to guide your operations through strategic
                    navigation, ultimately optimizing your logistics journey.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
