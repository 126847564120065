import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{
            backgroundImage:
              "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="page-header-shape-1">
          <img src="assets/images/shapes/page-header-shape-1.png" alt="" />
        </div>
        <div className="container">
          <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li>
                <span>/</span>
              </li>
              <li>Railway Transportation </li>
            </ul>
            <h2>Railway Transportation </h2>
          </div>
        </div>
      </section>
      <section className="insurance-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-5">
              <div className="insurance-details__left">
                <div className="insurance-details__services-box">
                  <ul className="insurance-details__services-list list-unstyled">
                    <li>
                      <Link to="/Sea">
                        Sea Freight
                        <span className="icon-right-arrow1" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/Air">
                        Air Freight
                        <span className="icon-right-arrow1" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/Inland">
                        Inland Transport
                        <span className="icon-right-arrow1" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/Railway">
                        Railway Transportation
                        <span className="icon-right-arrow1" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/Third">
                        Third Party Logistics (3PL)
                        <span className="icon-right-arrow1" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/Warehousing">
                        Warehousing
                        <span className="icon-right-arrow1" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/Cargo">
                        Cargo Insurance
                        <span className="icon-right-arrow1" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/Project">
                        Project Management
                        <span className="icon-right-arrow1" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/Supply">
                        Total Supply Chain Solution
                        <span className="icon-right-arrow1" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7">
              <div className="insurance-details__right">
                <div className="insurance-details__img-1">
                  <img src="assets/images/Railway Transportation.jpg" alt="" />
                </div>
                <div className="insurance-details__age-box">
                  <div className="insurance-details__age-title-box">
                    <h3 className="insurance-details__age-title">
                      Railway Transportation
                    </h3>
                  </div>
                </div>
                When it comes to delivering combined freight and ensuring
                door-to-door delivery to remote regions within a country's
                boundaries, road freight emerges as a pragmatic and suitable
                choice. {companyname}'s ground freight shipping services
                encompass an extensive array, spanning both domestic and
                international cross-border trucking solutions. Our approach
                hinges on delving into the unique requirements of your business,
                enabling us to tailor our services to not just meet but exceed
                even the most stringent standards. The truckload arm of {companyname} extends its reach by incorporating "spot"
                resources into an expansive network of authorized truck
                carriers, guaranteeing comprehensive end-to-end coverage.
                Expectations Met:
                <ul>
                  <li>
                    Round-the-clock Customer Support for Tracking, Quotes, and
                    Bookings
                  </li>
                  <li>Full Truckload Services</li>
                  <li>Specialized Equipment Solutions</li>
                  <li>Cross-border and Trans-border Expertise</li>
                  <li>Flexible and Highly Efficient Road Freight Services</li>
                  <li>
                    Domestic Full Truckload (FTL) and Less Than Truckload (LTL)
                    Services
                  </li>
                  <li>Integrated Multimodal and Combined Freight Services</li>
                  <li>24/7 Customer Support</li>
                </ul>
                Our commitment to providing exceptional road freight services
                rests not only on the comprehensive array of offerings but also
                on our unwavering dedication to round-the-clock customer
                support. At {companyname}, we're not just delivering
                goods; we're providing solutions that drive efficiency and
                efficacy in your logistical endeavors.
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
