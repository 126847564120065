import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{ backgroundImage: "url(assets/images/banner.jpg)" }}
        ></div>
        <div className="container">
          <div className="page-header__inner">
            <h2>High Tech</h2>
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>

              <li>
                <span>/</span>
              </li>
              <li>High Tech</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="blog-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7">
              <div className="blog-details__left">
                <div className="blog-details__img">
                  <img
                    src="assets/images/High Tech.jpg"
                    style={{ height: "400px" }}
                    alt=""
                  />
                </div>
                <div className="blog-details__content">
                  <h2 className="text-center  p-2">High Tech Industry</h2>
                  <p className="blog-details__text-1">
                    <p>
                    {companyname} stands as a pioneer in devising
                      and implementing distinctive, customized Hi Tech logistics
                      solutions meticulously tailored to meet the intricate and
                      demanding shipping and transportation requirements you
                      encounter. Our profound understanding of the challenges
                      presented by the high-tech freight and logistics landscape
                      positions us as the ideal partner for addressing your
                      needs.
                    </p>

                    <p>
                      The high tech industry is a realm characterized by its
                      exacting demands, innovative spirit, and the presence of
                      high-value commodities. Within this dynamic arena, {companyname} emerges as a responsive ally, equipped
                      to navigate the intricacies of high tech logistics. We
                      provide adaptable and secure solutions that traverse the
                      entirety of your high tech logistical endeavors. From
                      component provisioning through Vendor-Managed Inventory
                      (VMI) to end-of-life recycling, our support spans the
                      entire spectrum of the product value chain.
                    </p>

                    <p>
                      Our commitment extends beyond mere logistics – we
                      facilitate direct deliveries to resellers and end users,
                      ensuring seamless order management across diverse sales
                      channels. The essence of our approach in high tech
                      logistics is expediting speed-to-market and ensuring
                      comprehensive track & trace capabilities that align with
                      our customers' unique demands. Through each phase of the
                      product life cycle, we remain steadfast in our goal to
                      optimize efficiency and deliver unparalleled track & trace
                      solutions within the high tech logistics domain.
                    </p>
                  </p>
                  <div className="blog-details__bottom"></div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="sidebar">
                <div className="sidebar__single sidebar__category">
                  <h3 className="sidebar__title">Industry Sectors</h3>
                  <ul className="sidebar__category-list list-unstyled">
                    <li>
                      <Link to="/Automotive">Automotive</Link>
                    </li>
                    <li>
                      <Link to="/Electronics">Electronics</Link>
                    </li>
                    <li>
                      <Link to="/Retail">Retail</Link>
                    </li>
                    <li>
                      <Link to="/High">High Tech</Link>
                    </li>
                    <li>
                      <Link to="/Industrial">Industrial</Link>
                    </li>
                    <li>
                      <Link to="/Apparel">Apparel</Link>
                    </li>
                    <li>
                      <Link to="/Healthcare">Healthcare</Link>
                    </li>
                    <li>
                      <Link to="/Food">Food & Beverages</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
