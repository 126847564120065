import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{ backgroundImage: "url(assets/images/banner.jpg)" }}
        ></div>
        <div className="container">
          <div className="page-header__inner">
            <h2>Healthcare</h2>
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>

              <li>
                <span>/</span>
              </li>
              <li>Healthcare</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="blog-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7">
              <div className="blog-details__left">
                <div className="blog-details__img">
                  <img
                    src="assets/images/Healthcare.jpg"
                    style={{ height: "400px" }}
                    alt=""
                  />
                </div>
                <div className="blog-details__content">
                  <h2 className="text-center  p-2">Healthcare Industry</h2>
                  <p className="blog-details__text-1">
                    <p>
                      We deeply understand the critical role that
                      temperature-controlled logistics solutions play in the
                      pharmaceutical and healthcare sectors. Recognizing the
                      sensitive nature of these industries, our team possesses a
                      comprehensive understanding of the challenges they face.
                      From supply shortages to outdated inventory and
                      inefficient delivery practices, we are well-versed in the
                      intricate issues impacting healthcare operations.
                    </p>

                    <p>
                      Our commitment extends beyond awareness; we offer
                      end-to-end technological solutions tailored to address
                      these challenges head-on. We specialize in facilitating
                      the seamless transportation of medical and pharmaceutical
                      goods, leveraging established shipping channels. In a
                      landscape where warehouses are in a constant state of flux
                      due to frequent transfers of goods, our tech-driven
                      strategies come into play.
                    </p>

                    <p>
                      One of our key strategies involves meticulous sorting and
                      batch assignment, with each batch assigned a unique
                      identification number. This strategic organization proves
                      invaluable in efficiently managing goods that are
                      constantly on the move. This approach is particularly
                      crucial for perishable items like food, vitamins,
                      proteins, as well as pharmaceutical and medical supplies.
                      The application of these identification numbers serves as
                      a powerful tool to streamline inventory management,
                      organization, and overall handling procedures.
                    </p>
                  </p>
                  <div className="blog-details__bottom"></div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="sidebar">
                <div className="sidebar__single sidebar__category">
                  <h3 className="sidebar__title">Industry Sectors</h3>
                  <ul className="sidebar__category-list list-unstyled">
                    <li>
                      <Link to="/Automotive">Automotive</Link>
                    </li>
                    <li>
                      <Link to="/Electronics">Electronics</Link>
                    </li>
                    <li>
                      <Link to="/Retail">Retail</Link>
                    </li>
                    <li>
                      <Link to="/High">High Tech</Link>
                    </li>
                    <li>
                      <Link to="/Industrial">Industrial</Link>
                    </li>
                    <li>
                      <Link to="/Apparel">Apparel</Link>
                    </li>
                    <li>
                      <Link to="/Healthcare">Healthcare</Link>
                    </li>
                    <li>
                      <Link to="/Food">Food & Beverages</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
